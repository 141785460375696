import React, { createContext, useState, useCallback } from 'react';

const initialModal = {
  title: '',
  content: '',
  primaryAction: null,
  secondaryAction: {
    content: 'Cancel',
  },
  isError: false,
  loading: false,
  visible: false,
  accept: null,
  type: null,
};

export const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(initialModal);

  const show = useCallback(
    (args) => {
      setModal({ ...initialModal, visible: true, ...args });
    },
    [modal],
  );

  const loading = useCallback((val) => {
    setModal((prevState) => ({
      ...prevState,
      loading: val,
    }));
  }, []);

  const hide = useCallback(() => {
    setModal({ ...modal, visible: false });
  }, [modal]);

  return (
    <ModalContext.Provider
      value={{
        hide,
        show,
        loading,
        modal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
